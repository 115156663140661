import {CustomDataTable, DataRowRequiredData} from '@bri/shared-components';
import {useEffect, useReducer, useState} from 'react';
import {Heading, Icon, Link, VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {useTranslation} from 'react-i18next';
import {AntDesign} from '@native-base/icons';
import {Filters, SortCriteria, SortedFilteredPaginatedRequest} from '@bri/shared-core';
import moment from 'moment';
import {ISharedFileDTO} from '@bri/license-core/';
import {useSharedFilesService} from '../services/SharedFilesService';

type DataRow = DataRowRequiredData & Partial<ISharedFileDTO>;

const conditionalRowStyles = [
  {
    when: (item: DataRow) => moment(item.until!).isBefore(),
    style: {
      opacity: 0.6,
    },
  },
];

export function ListSharedFiles() {
  const {t} = useTranslation();
  const sharedFilesService = useSharedFilesService();

  const [sharedFiles, setSharedFiles] = useState<DataRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [sort, setSort] = useState<SortCriteria>({});
  const [filters, setFilters] = useState<Filters>([]);

  const [dataForce, forceUpdate] = useReducer(x => x + 1, 0);

  async function fetchData() {
    const request: SortedFilteredPaginatedRequest = {page, pageSize, sort, filters};

    sharedFilesService
      .list({...request})
      .response(resp => {
        setSharedFiles(resp.results as DataRow[]);
        setTotalResults(resp.total);
      })
      .error(console.error)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData().catch(console.error);
  }, [page, pageSize, sort, dataForce]);

  if (loading) return null;

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Type'),
      selector: (row: DataRow) => row.type as string,
      sortable: true,
      grow: 1,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.type}
        </Heading>
      ),
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: t('Hash'),
      selector: (row: DataRow) => row.hash as string,
      sortable: true,
      grow: 3,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.hash}
        </Heading>
      ),
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: t('Owner'),
      selector: (row: DataRow) => row.owner as string,
      sortable: true,
      grow: 2,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.owner}
        </Heading>
      ),
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: t('Until'),
      selector: (row: DataRow) => row.until as string,
      sortable: true,
      grow: 1,
      center: true,
      cell: item => {
        const until = moment(item.until);

        return (
          <Heading fontSize="14" justifyItems="self-start">
            {until.year() === 3000 ? t('Unlimited') : until.format('YYYY-MM-DD hh:mm:ss')}
          </Heading>
        );
      },
      conditionalCellStyles: conditionalRowStyles,
    },
    {
      name: t('Actions'),
      center: true,
      style: customStyles,
      cell: item => (
        <Link href={item.download as string}>
          {moment(item.until).isSameOrAfter() && <Icon as={AntDesign} name="download" size={4} color="primary.400" title={t('Download') as string} />}
        </Link>
      ),
      conditionalCellStyles: conditionalRowStyles,
    },
  ];
  return (
    <VStack space={2} alignItems="center">
      <CustomDataTable
        columns={columns}
        data={sharedFiles}
        export
        pagination
        showExtensions={false}
        defaultActions={false}
        customStyles={customStyles}
        totalRows={totalResults}
        onChangePage={newPage => setPage(newPage)}
        onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
        onSort={(newSort: SortCriteria) => setSort(newSort)}
        onFilter={(newFilters: Filters) => {
          setFilters(newFilters);
          forceUpdate();
        }}
      />
    </VStack>
  );
}

const customStyles: TableStyles = {
  tableWrapper: {
    style: {
      padding: 4,
    },
  },
  table: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  header: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  headCells: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
};
