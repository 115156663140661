import {FormUser} from '@bri/shared-components';
import {useNavigate, useParams} from 'react-router-dom';
import * as Yup from 'yup';
import {Message} from 'yup';
import {useTranslation} from 'react-i18next';
import {useEffect, useState} from 'react';
import {PageLayout} from '../components/PageLayout';

const FormUsersPage = () => {
  const {t} = useTranslation();
  const {userId} = useParams();
  const [isEditForm, setIsEditForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      setIsEditForm(true);
    }
  });

  const editFunction = () => navigate('/users');

  return (
    <PageLayout>
      <FormUser
        userId={userId}
        fields={[
          {
            userParam: 'username',
            icon: 'user',
            validation: Yup.string().required(t('Required') as Message),
          },
          {
            userParam: 'password',
            icon: 'lock',
            validation: Yup.string()
              .min(8, t('Too Short') as Message)
              .concat(isEditForm ? Yup.string().notRequired() : Yup.string().required(t('Required') as Message)),
            isOptional: isEditForm,
          },
          {
            userParam: 'email',
            icon: 'envelope',
            validation: Yup.string()
              .email()
              .required(t('Required') as Message),
          },
          {
            userParam: 'name',
            icon: 'user',
            validation: Yup.string().required(t('Required') as Message),
          },
          // Optionals fields:
          {
            userParam: 'jobTitle',
            icon: 'folder',
            validation: Yup.string(),
            isOptional: true,
          },
        ]}
        editHandler={editFunction}
      />
    </PageLayout>
  );
};

export default FormUsersPage;
