import {UserServiceTypes} from '@bri/license-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useUserService() {
  const baseService = useBaseService();

  const usersServiceFunctions: ServiceFunctionsType<UserServiceTypes> = {
    register: params => baseService().post('/v1/oauth/register', params),
  };

  return usersServiceFunctions;
}
