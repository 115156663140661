import {CustomDataTable, DataRowRequiredData} from '@bri/shared-components';
import {useEffect, useReducer, useState} from 'react';
import {Button, Heading, HStack, Icon, Link, Modal, Pressable, ShareIcon, Text, Tooltip, useTheme, VStack} from 'native-base';
import {TableColumn, TableStyles} from 'react-data-table-component';
import {useTranslation} from 'react-i18next';
import {AntDesign, MaterialCommunityIcons} from '@native-base/icons';
import {Filters, SortCriteria, SortedFilteredPaginatedRequest} from '@bri/shared-core';
import {IFile, IFileAccessLogDTO} from '@bri/license-core';
import {Timeline, TimelineEvent} from 'react-event-timeline';
import {useFilesService} from '../services/FilesService';
import ShareFileModal from './ShareFileModal';

export type DataRow = DataRowRequiredData & Partial<IFile>;

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const exampleData: DataRow[] = [];

type Props = {
  refreshList?: boolean;
};

export function ListFiles(props: Props) {
  const {t} = useTranslation();
  const filesService = useFilesService();

  const [files, setFiles] = useState<DataRow[]>(exampleData);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(10);
  const [totalResults, setTotalResults] = useState<number>(0);
  const [sort, setSort] = useState<SortCriteria>({});
  const [filters, setFilters] = useState<Filters>([]);
  const [showModal, setShowModal] = useState(false);
  const [fileHash, setFileHash] = useState('');

  const [dataForce, forceUpdate] = useReducer(x => x + 1, 0);

  useEffect(() => {
    if (props.refreshList) {
      forceUpdate();
    }
  }, [props.refreshList]);

  async function fetchData() {
    const request: SortedFilteredPaginatedRequest = {page, pageSize, sort, filters};

    filesService
      .list({...request})
      .response(resp => {
        setFiles((resp.results as unknown) as DataRow[]);
        setTotalResults(resp.total);
      })
      .error(console.error)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    fetchData().catch(console.error);
  }, [page, pageSize, sort, dataForce]);

  const [showShareModal, setShowShareModal] = useState<boolean>(false);
  const [shareModalFile, setShareModalFile] = useState<DataRow | undefined>(undefined);

  if (loading) return null;

  const columns: TableColumn<DataRow>[] = [
    {
      name: t('Mime Type'),
      selector: (row: DataRow) => row.name as string,
      sortable: true,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.type}
        </Heading>
      ),
    },
    {
      name: t('Hash'),
      selector: (row: DataRow) => row.name as string,
      sortable: true,
      grow: 3,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.hash}
        </Heading>
      ),
    },
    {
      name: t('External'),
      selector: (row: DataRow) => row.name as string,
      sortable: true,
      cell: item => (
        <Heading fontSize="14" justifyItems="self-start">
          {item.external ? t('Yes') : t('No')}
        </Heading>
      ),
    },
    {
      name: t('Actions'),
      center: true,
      style: customStyles,
      cell: item => (
        <HStack space={4}>
          <Tooltip label={t('Download') as string} openDelay={250} placement="top">
            <Link href={item.download as string}>
              <Icon as={AntDesign} name="download" size={4} color="primary.400" />
            </Link>
          </Tooltip>
          <Tooltip label={t('Traceability') as string} openDelay={250} placement="top">
            <Pressable
              onPress={() => {
                setFileHash(item.hash!);
                setShowModal(true);
              }}>
              <Icon as={MaterialCommunityIcons} name="timeline-clock-outline" size={4} color="primary.400" />
            </Pressable>
          </Tooltip>
          <Tooltip label={t('Share')!} openDelay={250} placement="top">
            <Pressable
              onPress={() => {
                setShareModalFile(item);
                setShowShareModal(true);
              }}>
              <ShareIcon size={4} color="primary.400" />
            </Pressable>
          </Tooltip>
        </HStack>
      ),
    },
  ];
  return (
    <VStack space={2} alignItems="center">
      <>
        <CustomDataTable
          columns={columns}
          data={files}
          export
          pagination
          showExtensions={false}
          defaultActions={false}
          customStyles={customStyles}
          totalRows={totalResults}
          onChangePage={newPage => setPage(newPage)}
          onChangeRowsPerPage={newPageSize => setPageSize(newPageSize)}
          onSort={(newSort: SortCriteria) => setSort(newSort)}
          onFilter={(newFilters: Filters) => {
            setFilters(newFilters);
            forceUpdate();
          }}
        />
        <TraceabilityModal showModal={showModal} setShowModal={setShowModal} hash={fileHash} />
        <ShareFileModal showModal={showShareModal} setShowModal={setShowShareModal} fileId={shareModalFile?._id} fileType={shareModalFile?.type} />
      </>
    </VStack>
  );
}

function TraceabilityModal({showModal, setShowModal, hash}: {showModal: boolean; setShowModal: any; hash: string}) {
  const {t} = useTranslation();
  const theme = useTheme();
  const filesService = useFilesService();

  const [loading, setLoading] = useState(false);
  const [fileAccessLogs, setFileAccessLogs] = useState<IFileAccessLogDTO[]>([]);

  async function fetchData() {
    filesService
      .listAccessLog({fileHash: hash})
      .response(resp => {
        setFileAccessLogs(resp.fileAccessLog);
      })
      .error(console.error)
      .finally(() => setLoading(false));
  }

  useEffect(() => {
    setLoading(true);
    showModal && hash && fetchData().catch(console.error);
  }, [hash, showModal]);

  useEffect(() => {
    !showModal && setFileAccessLogs([]);
  }, [showModal]);

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
      <Modal.Content maxWidth="800px">
        <Modal.CloseButton />
        <Modal.Header>{t('Traceability')}</Modal.Header>
        <Modal.Body>
          {fileAccessLogs.length > 0 && !loading ? (
            <Timeline
              lineColor={theme.colors.primary[500]}
              style={{fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif"}}>
              {fileAccessLogs.map(fileAccessLog => (
                <TimelineEvent
                  title={fileAccessLog.created}
                  titleStyle={{fontSize: 16, fontWeight: 500}}
                  subtitle={t('File accessed by: {{user}}', {user: fileAccessLog.accessEmail || fileAccessLog.address})}
                  subtitleStyle={{fontSize: 16, fontWeight: 400}}
                  bubbleStyle={{backgroundColor: theme.colors.secondary[500], borderColor: theme.colors.primary[500]}}
                  icon={<Icon as={AntDesign} name="download" size={4} color="primary.400" />}>
                  {t('View transaction')}:
                  <Link isExternal href={`https://blockscout.circulartrust.blueroominnovation.com/tx/${fileAccessLog.txHash}`}>
                    {fileAccessLog.txHash}
                  </Link>
                </TimelineEvent>
              ))}
            </Timeline>
          ) : (
            <Text fontSize="md">{loading ? t('Fetching file access logs...') : t('The document has not been downloaded')}</Text>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="ghost"
            onPress={() => {
              setShowModal(false);
            }}>
            Cancel
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

const customStyles: TableStyles = {
  tableWrapper: {
    style: {
      padding: 4,
    },
  },
  table: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  header: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
  headCells: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
      fontSize: 14,
    },
  },
  pagination: {
    style: {
      fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;',
    },
  },
};
