import {VStack, Text, HStack, Input, Button, Icon, ArrowBackIcon, AddIcon, FormControl} from 'native-base';
import {useTranslation} from 'react-i18next';
import {Ionicons} from '@native-base/icons';
import {ChangeEvent, useRef, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

type Props = {
  onSubmit: (data: any) => void;
  onBack: () => void;
  isLoading?: boolean;
};

export const FormAddByUpload = (props: Props) => {
  const {t} = useTranslation();
  const [fileUploaded, setFileUploaded] = useState<File>();
  const inputFileRef = useRef(null);

  const addByUploadSchema = Yup.object().shape({
    file: Yup.string()
      .test('file', t('Required') as Yup.Message, () => fileUploaded != undefined)
      .required(t('Required') as Yup.Message),
  });

  const {
    control,
    setValue,
    formState: {errors},
    handleSubmit,
  } = useForm({resolver: yupResolver(addByUploadSchema)});

  const handleFileUploadChange = (e: ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const file = e.target.files[0];
        setFileUploaded(file);
        setValue('file', file.name);
      }
    } catch {
      console.log('Could not select the file');
      setFileUploaded(undefined);
      setValue('file', undefined);
    }
  };

  const handleUploadFile = () => {
    (inputFileRef.current! as any).click();
  };

  return (
    <>
      <VStack>
        <Text pb={2}>{t('Choose a file from your device:')}</Text>
        <HStack space={2} justifyItems="flex-start">
          <FormControl flex="fit-content" isRequired isInvalid={'file' in errors} key="file">
            <Controller name="file" control={control} render={({field}) => <Input h={12} isDisabled placeholder="Choose file..." {...field} />} />
            {errors.file && <FormControl.ErrorMessage>{errors.file?.message}</FormControl.ErrorMessage>}
          </FormControl>

          <Button h={12} flex="fit-content" borderRadius={10} leftIcon={<Icon as={Ionicons} size={5} name="attach" />}
onPress={() => handleUploadFile()}>
            {t('Upload')}
          </Button>
        </HStack>
      </VStack>
      <input
        type="file"
        ref={inputFileRef}
        hidden
        onChange={event => {
          handleFileUploadChange(event);
        }}
      />

      <HStack justifyContent="center" space={4} pt={8}>
        <Button leftIcon={<ArrowBackIcon />} variant="outline" rounded="lg" onPress={() => props.onBack()}>
          {t('Back')}
        </Button>
        <Button
          isLoading={props.isLoading}
          isLoadingText={t('Adding...') as string}
          leftIcon={<AddIcon />}
          rounded="lg"
          onPress={handleSubmit(values => props.onSubmit(fileUploaded))}>
          {t('Add File')}
        </Button>
      </HStack>
    </>
  );
};
