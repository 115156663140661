import {PrivacyPolicy} from '@bri/shared-components';
import {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {PageLayout, Props} from '../components/PageLayout';

const PrivacyPolicyPage: FC = () => {
  const location = useLocation();
  const state = location?.state as Pick<Props, 'withoutLeftMargin'> | undefined | null;

  return (
    <PageLayout withoutLeftMargin={state?.withoutLeftMargin}>
      <PrivacyPolicy
        titleFontSize="2xl"
        company={{
          name: 'Blue Room Innovation SL.',
          id: 'B55237689',
          address: 'Gran Vía Carles III, 98 08028, Barcelona',
          contactEmail: 'info@blueroominnovation.com',
        }}
        projectName="License"
        appOrUrl="License"
      />
    </PageLayout>
  );
};

export default PrivacyPolicyPage;
