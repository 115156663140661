import {Text, HStack, Input, Button, FormControl, ArrowBackIcon, AddIcon} from 'native-base';
import {useTranslation} from 'react-i18next';
import {Controller, useForm} from 'react-hook-form';
import * as Yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';

type Props = {
  onSubmit: (data: any) => void;
  onBack: () => void;
  isLoading?: boolean;
};

export const FormAddByUrl = (props: Props) => {
  const {t} = useTranslation();

  const addByUrlSchema = Yup.object().shape({
    url: Yup.string().required(t('Required') as Yup.Message),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm({resolver: yupResolver(addByUrlSchema)});

  return (
    <>
      <Text pb={2}>{t('File URL:')}</Text>

      <FormControl isRequired isInvalid={'url' in errors} key="url" flex={1}>
        <Controller name="url" control={control} render={({field}) => <Input minW={10} placeholder="Ex: https://..." {...field} />} />
        {errors.url && <FormControl.ErrorMessage>{errors.url?.message}</FormControl.ErrorMessage>}
      </FormControl>

      <HStack justifyContent="center" space={4} pt={8}>
        <Button leftIcon={<ArrowBackIcon />} variant="outline" rounded="lg" onPress={() => props.onBack()}>
          {t('Back')}
        </Button>
        <Button
          isLoading={props.isLoading}
          isLoadingText={t('Adding...') as string}
          leftIcon={<AddIcon />}
          rounded="lg"
          onPress={handleSubmit(values => props.onSubmit(values.url))}>
          {t('Add File')}
        </Button>
      </HStack>
    </>
  );
};
