import {DatePicker, TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {yupResolver} from '@hookform/resolvers/yup';
import {Box, Button, Checkbox, FormControl, HStack, Icon, Input, Modal, VStack, Text} from 'native-base';
import {FC, useEffect, useRef} from 'react';
import {Control, Controller, FieldErrors, SubmitHandler, UseFormSetValue, useForm} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import * as Yup from 'yup';
import {AntDesign} from '@native-base/icons';
import {SharedFilesServiceTypes} from '@bri/license-core';
import ShareJSONProperties, {ShareJSONPropertiesRef} from './ShareJSONProperties';
import {DataRow} from './ListFiles';
import {useSharedFilesService} from '../services/SharedFilesService';
import {useFilesService} from '../services/FilesService';

type PropertiesInput = {
  properties?: string[];
};

type Inputs = PropertiesInput & {
  email: string;
  address: string;
  date_expiration: Date | null;
};

type Props = {
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  fileId?: DataRow['_id'];
  fileType?: DataRow['type'];
};

const ShareFileModal: FC<Props> = ({showModal, setShowModal, fileId, fileType}) => {
  const {t} = useTranslation();
  const sharedFilesService = useSharedFilesService();
  const sharedToast = useSharedToast();
  const fileService = useFilesService();
  const jsonPropertiesRef = useRef<ShareJSONPropertiesRef>(null);

  const requiredMessage = t('Required')!;

  const ShareSchema = Yup.object().shape({
    email: Yup.string()
      .test('email-or-eth', 'Must be a valid email or valid ETH address', value => {
        // Comprueba si es un correo electrónico válido
        if (
          Yup.string()
            .email()
            .isValidSync(value)
        ) {
          return true;
        }

        // Comprueba si es una dirección ETH válida
        const ethAddressRegex = /^(0x[a-fA-F0-9]{40})$/;
        return ethAddressRegex.test(value);
      })
      .required(requiredMessage),
    date_expiration: Yup.string()
      .required(requiredMessage)
      .nullable(),
    properties: Yup.array()
      .of(Yup.string())
      .default(undefined),
  });

  const submitForm: SubmitHandler<Inputs> = data => {
    let email;
    let address;
    if (
      Yup.string()
        .email()
        .isValidSync(data.email)
    ) {
      email = data.email;
    } else {
      address = data.email;
    }

    const request: SharedFilesServiceTypes['share']['input'] = {
      fileId: fileId!,
      email,
      address,
      fields: data.properties,
    };

    if (data.date_expiration) {
      request.endDate = new Date((data.date_expiration as unknown) as string).toISOString();
    }

    sharedFilesService
      .share(request)
      .response(() => {
        setShowModal(false);
        sharedToast({
          title: t('File shared successfully'),
          description: '',
          status: TOAST_STATUS.SUCCESS,
          variant: TOAST_VARIANT.SUBTLE,
        });
      })
      .error(error => {
        sharedToast({
          title: t('Something has gone wrong'),
          description: error.type as string,
          status: TOAST_STATUS.ERROR,
          variant: TOAST_VARIANT.SUBTLE,
        });
      });
  };

  useEffect(() => {
    if (showModal) {
      return;
    }

    reset({
      email: '',
      date_expiration: new Date(),
    });

    jsonPropertiesRef.current?.reset();
  }, [showModal]);

  const {
    control,
    formState: {errors},
    handleSubmit,
    reset,
    getValues,
    watch,
    setValue,
  } = useForm<Inputs>({
    resolver: yupResolver(ShareSchema),
    defaultValues: {
      date_expiration: new Date(),
    },
  });

  if (!fileId || !fileType) {
    return null;
  }

  return (
    <Modal isOpen={showModal} onClose={() => setShowModal(false)} size="xl">
      <Modal.Content maxW="100%">
        <Modal.CloseButton />
        <Modal.Header>{t('Share File')}</Modal.Header>
        <Modal.Body>
          <VStack space={4}>
            <Text>{t('Do you want to share this file?')}</Text>

            <FormControl isInvalid={'email' in errors} key="email" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
                {t('Email or ETH address')}
              </FormControl.Label>
              <Box borderColor="muted.300" borderWidth={1} borderRadius={5}>
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => <Input type="text" keyboardType="email-address" variant="register" color="black"
placeholder={t('email or ETH address')!} {...field} />}
                />
              </Box>
              {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired={false} isInvalid={'date_expiration' in errors} key="date_expiration" flex={1}>
              <FormControl.Label _text={{bold: true, color: 'black'}}>{t('File expiration date')}</FormControl.Label>
              <HStack alignItems="center">
                <Controller
                  name="date_expiration"
                  control={control}
                  render={({field}) => (
                    <DatePicker
                      dateFormat="dd/MM/yyy"
                      locale="en"
                      topPlacement
                      onChangeDate={field.onChange}
                      date={field.value || undefined}
                      customInput={
                        <Input
                          type="text"
                          flex={1}
                          InputRightElement={<Icon as={AntDesign} name="calendar" size={4} color="primary.500" mr={2} />}
                          isDisabled={!getValues('date_expiration')}
                        />
                      }
                    />
                  )}
                />
                <Checkbox value="not_expire" isChecked={!watch('date_expiration')} ml={4} onChange={checked => setValue('date_expiration', checked ? null : new Date())}>
                  <Text fontSize="sm">{t('Unlimited')}</Text>
                </Checkbox>
              </HStack>
              {errors.date_expiration && <FormControl.ErrorMessage>{errors.date_expiration?.message}</FormControl.ErrorMessage>}
            </FormControl>

            <ShareJSONProperties
              fileId={fileId}
              fileType={fileType}
              control={(control as unknown) as Control<PropertiesInput>}
              errors={(errors as unknown) as FieldErrors<PropertiesInput>}
              setValue={(setValue as unknown) as UseFormSetValue<PropertiesInput>}
              service={fileService}
              ref={jsonPropertiesRef}
            />
          </VStack>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onPress={() => setShowModal(false)}>
            {t('Cancel')}
          </Button>
          <Button
            onPress={() => {
              handleSubmit(submitForm)();
            }}>
            {t('Share')}
          </Button>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
};

export default ShareFileModal;
