import React from 'react';
import {useTranslation} from 'react-i18next';
import {PageLayout} from '../components/PageLayout';
import {ListSharedFiles} from '../components/ListSharedFiles';

const ListFilesPage = () => {
  const {t} = useTranslation();

  return (
    <PageLayout title={t('List Shared Files')}>
      <ListSharedFiles />
    </PageLayout>
  );
};

export default ListFilesPage;
