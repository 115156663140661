import {CookiesConsent} from '@bri/shared-components';
import {FC} from 'react';
import {Location, useLocation} from 'react-router-dom';
import {PageLayout, Props} from '../components/PageLayout';

const CookiesConsentPage: FC = () => {
  const location = useLocation() as Location | null;
  const state = location?.state as Pick<Props, 'withoutLeftMargin'> | undefined | null;

  return (
    <PageLayout withoutLeftMargin={state?.withoutLeftMargin}>
      <CookiesConsent onRemoveAllCookies={() => window.location.reload()} titleFontSize="2xl" />
    </PageLayout>
  );
};

export default CookiesConsentPage;
