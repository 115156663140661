import {SharedFilesServiceTypes} from '@bri/license-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useSharedFilesService() {
  const baseService = useBaseService();

  const sharedFilesServiceFunctions: ServiceFunctionsType<SharedFilesServiceTypes> = {
    list: () =>
      baseService()
        .auth()
        .get('/v1/share/files/list'),
    share: params =>
      baseService()
        .auth()
        .post('/v1/share/files/share', params),
  };

  return sharedFilesServiceFunctions;
}
