import {Heading, Hidden, HStack, View} from 'native-base';
import {useRecoilState} from 'recoil';
import {loggedUser} from '@bri/shared-components';
import {ReactElement} from 'react';

export type Props = {
  title?: any;
  children: any;
  button?: ReactElement;
  withoutLeftMargin?: boolean;
};

export const PageLayout = (props: Props) => {
  const [user] = useRecoilState(loggedUser);
  const withoutLeftMargin = props.withoutLeftMargin || false;

  return (
    <div
      className="outlet"
      style={{
        zIndex: -1,
        minHeight: user ? 'calc(100vh - 80px)' : '100vh',
        marginTop: user ? 80 : 0,
        marginLeft: withoutLeftMargin ? 0 : undefined,
      }}>
      <View flex={1} my={4} px={6} py={2.5}>
        {/* Aquest View es per a que quan es fa pantalla petita i apareix la barra superior, el contingut baixi i es vegi completament */}
        {!user && (
          <Hidden from="lg">
            <View mt={20} flex={1}>
              {' '}
            </View>
          </Hidden>
        )}
        {props.title && (
          <HStack justifyContent="space-between" flex={1} alignItems="center" mb={3}>
            <Heading fontSize="2xl" my={2}>
              {props.title}
            </Heading>
            {props.button && <View flex={1}>{props.button}</View>}
          </HStack>
        )}
        {props.children}
      </View>
    </div>
  );
};
