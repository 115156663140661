import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import {extendTheme, NativeBaseProvider} from 'native-base';
import fontsCSS from '@native-base/icons/FontsCSS';
import {StorageService} from '@bri/shared-components';
import './index.css';
import '@bri/shared-components/dist/index.css';
import App from './App';

const style = document.createElement('style');
style.type = 'text/css';
style.appendChild(document.createTextNode(fontsCSS));
document.head.appendChild(style);

StorageService.setStorage(localStorage);

const theme = extendTheme({
  fontConfig: {
    Avenir: {
      100: {
        normal: 'Avenir-Light',
      },
      200: {
        normal: 'Avenir-Light',
      },
      300: {
        normal: 'Avenir-Regular',
      },
      400: {
        normal: 'Avenir-Regular',
      },
      500: {
        normal: 'Avenir-Regular',
      },
      600: {
        normal: 'Avenir-Regular',
      },
      700: {
        normal: 'Avenir-Black',
      },
    },
  },
  fonts: {
    heading: 'Avenir',
    body: 'Avenir',
    mono: 'Avenir',
    custom: 'Avenir',
  },
  fontSizes: {
    '2xs': 10,
    xs: 12,
    sm: 14,
    md: 16,
    lg: 22,
    xl: 20,
    '2xl': 24,
    '3xl': 30,
    '4xl': 36,
    '5xl': 48,
    '6xl': 60,
    '7xl': 72,
    '8xl': 96,
    '9xl': 128,
  },
  colors: {
    primary: {
      50: '#fde6e6',
      100: '#fbc2c1',
      200: '#f99998',
      300: '#f76f6e',
      400: '#f54948',
      500: '#cd5360',
      600: '#b64a56',
      700: '#993f4b',
      800: '#7d343f',
      900: '#581d24',
    },

    secondary: {
      50: '#FEF9F9',
      100: '#FDF2F1',
      200: '#FBEAEA',
      300: '#F9E3E2',
      400: '#F8DBDA',
      500: '#FEF9F9',
      600: '#F69E9D',
      700: '#F38887',
      800: '#F17271',
      900: '#EF5B5A',
    },
  },
  components: {
    Button: {
      // Can simply pass default props to change default behaviour of components.
      baseStyle: (props: any) => {
        if (props.variant === 'outline') {
          return {
            rounded: 'full',
            borderBottomColor: `${props.colorScheme}.500`,
            borderLeftColor: `${props.colorScheme}.500`,
            borderRightColor: `${props.colorScheme}.500`,
            borderTopColor: `${props.colorScheme}.500`,
          };
        }
        if (props.variant === 'login') {
          return {
            bg: 'primary.500',
            borderRadius: 7,
            width: '100%',
            _hover: {
              bg: `primary.600`,
            },
            _pressed: {
              bg: `primary.700`,
            },
            _text: {
              color: `primary.50`,
            },
            borderBottomColor: 'primary.500',
          };
        }
      },
    },
    Input: {
      baseStyle: {
        backgroundColor: 'secondary.500',
        borderRadius: 7,
        borderWidth: 0,
      },
      defaultProps: {
        size: 'md',
      },
      // variants: {
      //   login: () => ({
      //     borderWidth: 1,
      //   }),
      // },
    },
  },
  config: {
    initialColorMode: 'light',
  },
});

// extend the theme
type MyThemeType = typeof theme;
declare module 'native-base' {
  type ICustomTheme = MyThemeType;
}

ReactDOM.render(
  <React.StrictMode>
    <NativeBaseProvider theme={theme}>
      <App />
    </NativeBaseProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
