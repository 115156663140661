import {HStack, Modal, Button, View, Icon} from 'native-base';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AntDesign} from '@native-base/icons';
import {TOAST_STATUS, TOAST_VARIANT, useSharedToast} from '@bri/shared-components';
import {FormAddByUpload} from './addFile/FormAddByUpload';
import {FormAddByUrl} from './addFile/FormAddByUrl';
import {useFilesService} from '../services/FilesService';

type Props = {
  showModal?: boolean;
  onClose?: () => void;
  onFileAdded?: () => void;
};

export const ModalAddFile = (props: Props) => {
  const {t} = useTranslation();
  const sharedToast = useSharedToast();
  const filesService = useFilesService();
  const [addByUpload, setAddByUpload] = useState(false);
  const [addByURL, setAddByURL] = useState(false);
  const [loading, setLoading] = useState(false);

  const menuChooseMethod = () => (
    <HStack justifyContent="center" space={4}>
      <Button rounded="lg" leftIcon={<Icon as={AntDesign} name="upload" size={4} />} onPress={() => setAddByUpload(true)}>
        {t('Upload')}
      </Button>
      <Button rounded="lg" leftIcon={<Icon as={AntDesign} name="link" size={4} />} onPress={() => setAddByURL(true)}>
        {t('URL')}
      </Button>
    </HStack>
  );

  const onBack = () => {
    setAddByUpload(false);
    setAddByURL(false);
  };

  // Called after Yup validation
  const onSubmit = async (data: any) => {
    setLoading(true);
    let error = false;

    // Await to recieve server response
    const response = await new Promise(resolve => {
      if (addByUpload && data instanceof File) {
        filesService
          .upload({file: [data]})
          .response(res => {
            resolve(res);
          })
          .error(err => {
            error = true;
            resolve(err);
          });
      } else if (addByURL && typeof data === 'string') {
        filesService
          .uploadUrl({url: data})
          .response(res => {
            resolve(res);
          })
          .error(err => {
            error = true;
            resolve(err);
          });
      } else {
        error = true;
        resolve(t('Invalid data'));
      }
    });

    if (error) {
      console.log(response);
      sharedToast({
        title: t('Error adding file'),
        description: t((response as any)?.message) || '',
        status: TOAST_STATUS.ERROR,
        variant: TOAST_VARIANT.SUBTLE,
      });
    } else {
      sharedToast({
        title: t('File added successfully'),
        description: t(''),
        status: TOAST_STATUS.SUCCESS,
        variant: TOAST_VARIANT.SUBTLE,
      });
      props.onFileAdded?.();
    }

    props.onClose?.();
    setLoading(false);
  };

  return (
    <Modal isOpen={props.showModal} onClose={props.onClose} size="lg">
      <Modal.Content maxWidth="350">
        <Modal.CloseButton />
        <Modal.Header>{t('Add file') as string}</Modal.Header>
        <Modal.Body>
          {/* Modal content */}
          <View>
            {!addByUpload && !addByURL && menuChooseMethod()}
            {addByUpload && (
              <FormAddByUpload
                onSubmit={data => {
                  !loading && onSubmit(data);
                }}
                onBack={onBack}
                isLoading={loading}
              />
            )}
            {addByURL && (
              <FormAddByUrl
                onSubmit={data => {
                  !loading && onSubmit(data);
                }}
                onBack={onBack}
                isLoading={loading}
              />
            )}
          </View>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
};
