import {forwardRef, useEffect, useImperativeHandle, useState} from 'react';
import {Checkbox, FormControl, HStack, VStack, View, Text} from 'native-base';
import {t} from 'i18next';
import {Control, Controller, FieldErrors, UseFormSetValue} from 'react-hook-form';
import {ServiceFunctionsType} from '@bri/shared-components';
import {FilesServiceTypes} from '@bri/license-core';
import {chunk} from 'lodash';
import {DataRow} from './ListFiles';

type Input = {
  properties?: string[];
};

type Props = {
  fileId: DataRow['_id'];
  fileType: DataRow['type'];
  service: ServiceFunctionsType<FilesServiceTypes>;
  control: Control<Input>;
  errors: FieldErrors<Input>;
  setValue: UseFormSetValue<Input>;
};

export type ShareJSONPropertiesRef = {
  reset: () => void;
};

const JSON_MIME_TYPE = 'application/json';

const ShareJSONProperties = forwardRef<ShareJSONPropertiesRef, Props>(({fileId, fileType, service, control, errors, setValue}, ref) => {
  const [properties, setProperties] = useState<string[]>([]);
  const reset = () => setValue('properties', properties);

  useImperativeHandle(ref, () => ({reset}));

  useEffect(() => setValue('properties', properties), [properties]);

  useEffect(() => {
    if (fileType !== JSON_MIME_TYPE) {
      return;
    }

    service
      .getJsonProperties({id: fileId})
      .response(response => setProperties(response.properties))
      .error(() => console.error('UNLUCKY'));
  }, [fileId]);

  if (!fileId || fileType !== JSON_MIME_TYPE) {
    return null;
  }

  if (!properties) {
    return null;
  }

  const checkboxRows = chunk(
    properties.map(property => (
      <View flex={1 / 3}>
        <Checkbox value={property} alignItems="center">
          <Text overflow="clip" pr={4}>
            {property}
          </Text>
        </Checkbox>
      </View>
    )),
    3
  );

  return (
    <FormControl isInvalid={'properties' in errors} key="properties">
      <FormControl.Label _text={{bold: true, color: 'black'}} variant="register">
        {t('Properties')}
      </FormControl.Label>
      <Controller
        name="properties"
        control={control}
        render={({field}) => (
          <Checkbox.Group onChange={field.onChange} value={field.value}>
            <VStack width="100%">
              {checkboxRows.map(checkboxes => (
                <HStack alignContent="center" alignItems="center" padding={2} flex={1}>
                  {checkboxes}
                </HStack>
              ))}
            </VStack>
          </Checkbox.Group>
        )}
      />
    </FormControl>
  );
});

export default ShareJSONProperties;
