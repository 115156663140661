import {useState} from 'react';
import {Button, Checkbox, Flex, FormControl, Heading, HStack, Icon, Image, Input, View, VStack, Text, Link as NBLink} from 'native-base';
import {useTranslation} from 'react-i18next';
import {FontAwesome} from '@native-base/icons';
import {Controller, Message, SubmitHandler, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import {Link} from 'react-router-dom';
import {COOKIES_TYPES, CookiesService, TOAST_STATUS, TOAST_VARIANT, loggedUser, useAuthService, useSharedToast} from '@bri/shared-components';
import randomstring from 'randomstring';
import sha256 from 'sha256';
import {useSetRecoilState} from 'recoil';
import {RegisterUserDTO} from '@bri/license-core';
import {IUserBase} from '@bri/shared-core';
import {useUserService} from '../services/UserService';
import logo from '../images/neotec-logo.png';

type Inputs = {
  email: string;
  password: string;
  passwordConfirmation: string;
  policy: boolean | undefined;
  terms: boolean | undefined;
};

export const Register = () => {
  const {t} = useTranslation();
  const [passwordInputType, setPasswordInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordConfirmationInputType, setPasswordConfirmationInputType] = useState<'password' | 'text' | undefined>('password');
  const [passwordIcon, setPasswordIcon] = useState('eye-slash');
  const [passwordConfirmationIcon, setPasswordConfirmationIcon] = useState('eye-slash');
  const [isLoading, setIsLoading] = useState(false);
  const userService = useUserService();
  const authService = useAuthService();
  const sharedToast = useSharedToast();
  const setUser = useSetRecoilState(loggedUser);

  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email()
      .required(t('Email is required') as Message),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .required('Password is required'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), undefined], 'Passwords must match')
      .required('Password confirmation is mandatory'),
  });

  const {
    control,
    formState: {errors},
    handleSubmit,
  } = useForm<Inputs>({
    defaultValues: {policy: false, terms: false},
    resolver: yupResolver(RegisterSchema),
  });

  const submitForm: SubmitHandler<Inputs> = data => {
    setIsLoading(true);

    const codeVerifier = randomstring.generate();
    const state = randomstring.generate();
    const codeChallenge = sha256(codeVerifier);

    const user: RegisterUserDTO = {
      ...data,
      code_challenge_method: 'S256',
      redirect_uri: 'localhost',
      state,
      code_challenge: codeChallenge,
    };

    userService
      .register(user)
      .response(registerResp => {
        if (registerResp.authCode && registerResp.authState === state) {
          authService
            .token({
              grant_type: 'authorization_code',
              redirect_uri: 'localhost',
              code: registerResp.authCode,
              code_verifier: codeVerifier,
            })
            .response(async tokenResp => {
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'access_token', tokenResp.access_token);
              await CookiesService.setType(COOKIES_TYPES.TECHNICAL, 'refresh_token', tokenResp.refresh_token);
              setUser(registerResp.user as IUserBase);
            })
            .error(err => {
              setIsLoading(false);
              sharedToast({
                title: t('Algo ha salido mal'),
                description: err.type as string,
                status: TOAST_STATUS.ERROR,
                variant: TOAST_VARIANT.SUBTLE,
              });
            });
        } else {
          setIsLoading(false);
          sharedToast({
            title: t('Algo ha salido mal'),
            description: t('Server Error [OAUTH STATE]'),
            status: TOAST_STATUS.ERROR,
            variant: TOAST_VARIANT.SUBTLE,
          });
        }
      })
      .error(() =>
        sharedToast({title: t('Algo ha salido mal'), description: t('El nombre de usuario  ya esta en uso.'), status: TOAST_STATUS.ERROR, variant: TOAST_VARIANT.SUBTLE})
      )
      .finally(() => setIsLoading(false));
  };

  const handlePassword = () => {
    if (passwordInputType === 'text') {
      setPasswordInputType('password');
      setPasswordIcon('eye');
    } else {
      setPasswordInputType('text');
      setPasswordIcon('eye-slash');
    }
  };

  const handlePasswordConfirmation = () => {
    if (passwordConfirmationInputType === 'text') {
      setPasswordConfirmationInputType('password');
      setPasswordConfirmationIcon('eye');
    } else {
      setPasswordConfirmationInputType('text');
      setPasswordConfirmationIcon('eye-slash');
    }
  };

  return (
    <div
      style={{
        width: '100%',
        backgroundImage: 'url(/login_background.jpg)',
        backgroundSize: 'cover',
      }}>
      <Flex h="100vh" justifyContent="center" alignItems="center" bgColor="rgba(167, 0, 0, 0.4)">
        <View maxH={900} bgColor="white" px={10} pb={5}
borderRadius={20}>
          <VStack space={7} alignItems="center" mt={4}>
            <Heading fontSize="lg" alignSelf="flex-start">
              {t('Register')}
            </Heading>
            <Image source={{uri: logo}} alt={t('Icon text').toString()} size="lg" width={300} height={77} />
          </VStack>
          <HStack space={8} mt={4}>
            <VStack mb={6} alignSelf="flex-start" flex={1} space="md">
              <FormControl isRequired isInvalid={'email' in errors} key="email" flex={1}>
                <FormControl.Label _text={{fontSize: 'md'}} variant="register">
                  {t('Email')}
                </FormControl.Label>
                <Controller
                  name="email"
                  control={control}
                  render={({field}) => <Input type="text" keyboardType="email-address" variant="register" placeholder={t('Email')!} {...field} />}
                />
                {errors.email && <FormControl.ErrorMessage>{errors.email?.message}</FormControl.ErrorMessage>}
              </FormControl>
              <FormControl isRequired isInvalid={'password' in errors} key="password" flex={1}>
                <FormControl.Label _text={{fontSize: 'md'}} variant="register">
                  {t('Password')}
                </FormControl.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({field}) => (
                    <Input
                      type={passwordInputType}
                      variant="register"
                      InputRightElement={
                        <Icon
                          as={FontAwesome}
                          name={passwordIcon}
                          size={5}
                          mr={2}
                          color="muted.50"
                          onPress={() => {
                            handlePassword();
                          }}
                        />
                      }
                      pl={2}
                      {...field}
                    />
                  )}
                />
                {errors.password && <FormControl.ErrorMessage>{errors.password?.message}</FormControl.ErrorMessage>}
              </FormControl>

              <FormControl isRequired isInvalid={'passwordConfirmation' in errors} key="passwordConfirmation" flex={1}>
                <FormControl.Label _text={{fontSize: 'md'}} variant="register">
                  {t('Repeat Password')}
                </FormControl.Label>
                <Controller
                  name="passwordConfirmation"
                  control={control}
                  render={({field}) => (
                    <Input
                      type={passwordConfirmationInputType}
                      variant="register"
                      InputRightElement={
                        <Icon
                          as={FontAwesome}
                          name={passwordConfirmationIcon}
                          size={5}
                          mr={2}
                          color="muted.50"
                          onPress={() => {
                            handlePasswordConfirmation();
                          }}
                        />
                      }
                      {...field}
                    />
                  )}
                />
                {errors.passwordConfirmation && <FormControl.ErrorMessage>{errors.passwordConfirmation.message}</FormControl.ErrorMessage>}
              </FormControl>
            </VStack>
          </HStack>

          <VStack space={4} mt={4}>
            <FormControl isRequired isInvalid={'policy' in errors} key="policy">
              <Controller
                name="policy"
                control={control}
                render={({field}) => (
                  <Checkbox {...(field as any)} variant="register" _text={{fontSize: 14}} size="sm">
                    <Text fontSize="sm">
                      {t('I have read and accept the')}{' '}
                      <Link to="/privacyPolicy" style={{textDecoration: 'underline'}} state={{withoutLeftMargin: true}}>
                        {t('Privacy Policy')}
                      </Link>
                      .
                    </Text>
                  </Checkbox>
                )}
              />
              {errors.policy && <FormControl.ErrorMessage>{errors.policy.message}</FormControl.ErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={'terms' in errors} key="terms">
              <Controller
                name="terms"
                control={control}
                render={({field}) => (
                  <Checkbox {...(field as any)} variant="register" _text={{fontSize: 14}} size="sm">
                    <Text fontSize="sm">
                      {t('I have read and accept the')}{' '}
                      <Link to="/termsAndConditions" style={{textDecoration: 'underline'}} state={{withoutLeftMargin: true}}>
                        {t('Terms and Conditions')}
                      </Link>
                      .
                    </Text>
                  </Checkbox>
                )}
              />
              {errors.terms && <FormControl.ErrorMessage>{errors.terms.message}</FormControl.ErrorMessage>}
            </FormControl>
          </VStack>

          <Button
            variant="solid"
            onPress={handleSubmit(submitForm)}
            w="fit-content"
            shadow="2"
            style={{marginTop: 20}}
            alignSelf="center"
            px={8}
            bgColor="primary.500"
            _text={{fontSize: 'md', color: 'white'}}
            size="lg"
            mt={5}
            isLoading={isLoading}
            isLoadingText={t('Enviando')!}>
            {t('Register')}
          </Button>
        </View>
        <HStack mb={4} mx={4} position="absolute" bottom={4}>
          <View px={2}>
            <Link to="/cookies-config" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
              <NBLink
                isUnderlined={false}
                _text={{
                  color: 'white',
                }}>
                {t('Preferencias de cookies')}
              </NBLink>
            </Link>
          </View>
          <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
            <Link to="/cookies" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
              <NBLink
                isUnderlined={false}
                _text={{
                  color: 'white',
                }}>
                {t('Política de cookies')}
              </NBLink>
            </Link>
          </View>
          <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
            <Link to="/termsAndConditions" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
              <NBLink
                isUnderlined={false}
                _text={{
                  color: 'white',
                }}>
                {t('Términos y condiciones')}
              </NBLink>
            </Link>
          </View>
          <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
            <Link to="/privacyPolicy" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
              <NBLink
                isUnderlined={false}
                _text={{
                  color: 'white',
                }}>
                {t('Política privacidad')}
              </NBLink>
            </Link>
          </View>
        </HStack>
      </Flex>
    </div>
  );
};
