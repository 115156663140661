import {TermsAndConditions} from '@bri/shared-components';
import {FC} from 'react';
import {useLocation} from 'react-router-dom';
import {PageLayout, Props} from '../components/PageLayout';

const TermsAndConditionsPage: FC = () => {
  const location = useLocation();
  const state = location?.state as Pick<Props, 'withoutLeftMargin'> | undefined | null;

  return (
    <PageLayout withoutLeftMargin={state?.withoutLeftMargin}>
      <TermsAndConditions
        company={{
          name: 'Blue Room Innovation SL.',
          id: 'B55237689',
          address: 'Gran Vía Carles III, 98 08028, Barcelona',
          contactEmail: 'info@blueroominnovation.com',
        }}
        appOrUrl="License"
        projectName="License"
        titleFontSize="2xl"
      />
    </PageLayout>
  );
};

export default TermsAndConditionsPage;
