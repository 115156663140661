import {Box, Button, Icon} from 'native-base';
import {useMatch, useNavigate} from 'react-router-dom';
import React from 'react';
import {MENU_ITEM_WIDTH} from './Navbar';

type Props = {
  item: any;
  onPress: () => void;
};

export default function NavbarItem({item, onPress}: Props) {
  const match = useMatch(item.path);
  const navigate = useNavigate();

  return (
    <Box px={4} justifyContent="flex-start" height="auto" minHeight={55} py={1}>
      <Button
        variant="unstyled"
        onPress={() => {
          navigate(item.path);
          onPress && setTimeout(() => onPress(), 250);
        }}
        leftIcon={<Icon as={item.iconFamily} name={item.icon} size={21} mr={2.5} />}
        _text={{
          color: match ? 'primary.500' : 'secondary.500',
          alignItems: 'flex-start',
          width: MENU_ITEM_WIDTH,
        }}
        _hover={{
          backgroundColor: 'secondary.500',
          borderRadius: 5,
          _text: {color: 'primary.500'},
          _icon: {color: 'primary.500'},
        }}
        _icon={{color: match ? 'primary.500' : 'secondary.500'}}
        justifyContent="flex-start"
        size="lg"
        backgroundColor={match && 'secondary.500'}>
        {item.title}
      </Button>
    </Box>
  );
}
