import {createBrowserRouter} from 'react-router-dom';
import React from 'react';
import {USER_ACTIONS} from '@bri/license-core';
import Root from './root';
import ErrorPage from '../pages/ErrorPage';
import LoginPage from '../pages/LoginPage';
import ProtectedRoute from '../pages/ProtectedRoute';
import TermsAndConditionsPage from '../pages/TermsAndConditionsPage';
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage';
import CookiesPage from '../pages/CookiesPage';
import CookiesConsentPage from '../pages/CookiesConsentPage';
import UnAuthenticated from '../components/UnAuthenticated';
import FormUsersPage from '../pages/FormUsersPage';
import ListFilesPage from '../pages/ListFilesPage';
import ListSharedFilesPage from '../pages/ListSharedFilesPage';
import {Register} from '../pages/RegisterPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorPage />,
    // loader: rootLoader,
    // action: rootAction,
    children: [
      {
        path: 'login',
        element: (
          <UnAuthenticated>
            <LoginPage />
          </UnAuthenticated>
        ),
      },
      {
        path: 'register',
        element: (
          <UnAuthenticated>
            <Register />
          </UnAuthenticated>
        ),
      },
      {
        path: '',
        element: <ProtectedRoute action={USER_ACTIONS.FILES} />,
        children: [
          {
            path: '',
            element: <ListFilesPage />,
            errorElement: <ErrorPage />,
          },
          {
            path: 'file/form/:fileId',
            element: <FormUsersPage />,
          },
          {
            path: 'files/form',
            element: <FormUsersPage />,
          },
        ],
      },
      {
        path: 'shared',
        element: <ProtectedRoute action={USER_ACTIONS.SHARE_FILES} />,
        children: [
          {
            path: '',
            element: <ListSharedFilesPage />,
          },
          {
            path: 'file/form/:fileId',
            element: <FormUsersPage />,
          },
          {
            path: 'files/form',
            element: <FormUsersPage />,
          },
        ],
      },
      {
        path: 'termsAndConditions',
        element: <TermsAndConditionsPage />,
      },
      {
        path: 'privacyPolicy',
        element: <PrivacyPolicyPage />,
      },
      {
        path: 'cookies',
        element: <CookiesPage />,
      },
      {
        path: 'cookies-config',
        element: <CookiesConsentPage />,
      },
      {
        path: '*',
        element: <ErrorPage code={404} message={"There's nothing here: 404"} />,
      },
    ],
  },
]);
