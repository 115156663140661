import {AddIcon, Button} from 'native-base';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {PageLayout} from '../components/PageLayout';
import {ListFiles} from '../components/ListFiles';
import {ModalAddFile} from '../components/ModalAddFile';

const ListFilesPage = () => {
  const {t} = useTranslation();
  const [showModalAddFile, setShowModalAddFile] = useState(false);
  const [refreshList, setRefreshList] = useState(false);

  useEffect(() => {
    if (refreshList) {
      setRefreshList(false);
    }
  }, [refreshList]);

  return (
    <PageLayout
      title={t('List Files')}
      button={
        <Button onPress={() => setShowModalAddFile(true)} my={5} px={5} style={{width: 'auto', alignSelf: 'flex-end'}} leftIcon={<AddIcon />}>
          {t('Add File')}
        </Button>
      }>
      {showModalAddFile && (
        <ModalAddFile
          showModal={showModalAddFile}
          onClose={() => setShowModalAddFile(false)}
          onFileAdded={() => {
            setRefreshList(true);
          }}
        />
      )}
      <ListFiles refreshList={refreshList} />
    </PageLayout>
  );
};

export default ListFilesPage;
