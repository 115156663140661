import {Login} from '@bri/shared-components';
import {Box, Flex, VStack, Text, HStack, View, Link as NBLink} from 'native-base';

import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import logo from '../images/neotec-logo.png';

const LoginPage = () => {
  const {t} = useTranslation();

  return (
    <div className="outlet" style={{zIndex: -1, minHeight: '100vh', marginTop: 0, marginLeft: 0}}>
      <div
        style={{
          width: '100%',
          backgroundImage: 'url(/login_background.jpg)',
          backgroundSize: 'cover',
        }}>
        <div
          style={{
            width: '100%',
            background: 'rgba(167, 0, 0, 0.4)',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Flex h={window.innerHeight} style={{justifyContent: 'center', alignItems: 'center'}}>
            <VStack>
              <Login logo={{file: logo, width: 300, height: 75}} hidePasswordRecovery />
              <Box flexDirection="row" justifyContent="center" alignItems="center" marginTop={5}
borderRadius={10} w="100%" h={50} backgroundColor="white">
                <Text paddingRight={2}>Don't have an account?</Text>
                <Link to="/register" style={{textDecoration: 'underline'}}>
                  Sign up
                </Link>
              </Box>
            </VStack>
            <HStack mb={4} mx={4} position="absolute" bottom={4}>
              <View px={2}>
                <Link to="/cookies-config" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Preferencias de cookies')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/cookies" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Política de cookies')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/termsAndConditions" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Términos y condiciones')}
                  </NBLink>
                </Link>
              </View>
              <View style={{borderLeftWidth: 1, borderLeftColor: 'white'}} px={2}>
                <Link to="/privacyPolicy" style={{textDecoration: 'none', textAlign: 'center'}} state={{withoutLeftMargin: true}}>
                  <NBLink
                    isUnderlined={false}
                    _text={{
                      color: 'white',
                    }}>
                    {t('Política privacidad')}
                  </NBLink>
                </Link>
              </View>
            </HStack>
          </Flex>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
