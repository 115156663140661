import {FilesServiceTypes} from '@bri/license-core';
import {useBaseService} from '@bri/shared-components';
import {ServiceFunctionsType} from '@bri/shared-core';

export function useFilesService() {
  const baseService = useBaseService();

  const filesServiceFunctions: ServiceFunctionsType<FilesServiceTypes> = {
    list: () =>
      baseService()
        .auth()
        .get('/v1/files/list'),
    upload: params =>
      baseService()
        .auth()
        .postFile('/v1/files/upload', params, ['file']),
    uploadUrl: params =>
      baseService()
        .auth()
        .postFile('/v1/files/uploadUrl', params),
    listAccessLog: params =>
      baseService()
        .auth()
        .get('/v1/file/access/logger/list', params),
    getJsonProperties: params =>
      baseService()
        .auth()
        .get('/v1/files/getJsonProperties', params),
  };

  return filesServiceFunctions;
}
